<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row">
      <div class="col-6">
        <div v-if="!readOnly">
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-start"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add-doc-persona"
          >
            <i class="bi bi-plus fs-6 text-gray-700"></i>aggiungi documento
          </button>
          <AddDocPersona
            :persona="persona"
            @documentAdded="getDocumentiPersona"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Documenti:
          <b>{{
            documenti_persona_list ? documenti_persona_list.length : 0
          }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        documenti_persona_list.length === 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-t5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-t5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <a
          v-else-if="!hasBacheca"
          :href="redirectInsegnanti"
          class="btn text-hover-primary text-gray-600 fs-5 text-end p-0 m-0"
        >
          <i class="bi bi-house fs-2 text-gray-800"> </i>
        </a>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
      </span>
    </div>
    <div class="table-responsive card shadow" v-else>
      <Datatable
        :total="documenti_persona_list.length"
        :table-header="tableHeader"
        :table-data="documenti_persona_list"
        :rows-per-page="10"
        :enable-items-per-page-dropdown="false"
        @sort="setSortOrderColumnDocPersona"
        @items-per-page-change="setFetchRowsDocPersona"
        @current-change="setCurrentPageDocPersona"
      >
        <template v-slot:cell-file_name="{ row: data }">
          <div @click="downloadDocument(data.guid_documento, data.file_name)">
            <PreviewDaEstensione
              :estensione="
                data.file_name.substring(
                  data.file_name.lastIndexOf('.') + 1,
                  data.file_name.length
                )
              "
            />
          </div>
        </template>
        <template v-slot:cell-tipo="{ row: data }">
          {{ data.tipo }}
        </template>
        <template v-slot:cell-Oggetto="{ row: data }">
          {{ data.Oggetto }}
        </template>
        <template v-slot:cell-Descrizione="{ row: data }">
          {{ data.Descrizione }}
        </template>
        <template v-slot:cell-data_add="{ row: data }">
          {{ data.data_add }}
        </template>
        <template v-slot:cell-options="{ row: data }">
          <div class="btn-group" @click="selectedDocId = data.guid_documento">
            <button
              class="btn btn-sm dropdown"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
            </button>
            <ul class="dropdown-menu">
              <a
                href="#"
                @click="downloadDocument(data.guid_documento, data.file_name)"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-download text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7" type="button">
                        Download
                      </span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="!readOnly"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_doc-persona"
                @click="disabled = true"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-eye text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7" type="button">
                        Visualizza
                      </span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="!readOnly"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_doc-persona"
                @click="disabled = false"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-pen text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7"> Modifica</span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="isEnabled('fnPersoneDeleteDocumentoPersona')"
                @click="eliminaFile"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-trash text-dark fs-5"></i>
                      <span class="text-dark ps-1 fs-7"> Elimina </span>
                    </div>
                  </div>
                </li></a
              >
            </ul>
          </div>
        </template>
      </Datatable>
    </div>
    <br />
    <DocPersonaDetail
      :disabled="disabled"
      :persona="persona"
      :selectedId="selectedDocId"
      @eliminaDocumento="eliminaFile(data.id_documento)"
      @documentEdited="
        getDocumentiPersona();
        selectedDocId = null;
      "
      @resetIdDoc="selectedDocId = null"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  getCurrentInstance,
  computed,
  onBeforeMount,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import DocPersonaDetail from "@/components/components-fit/persone/dettaglio-persona/DocPersonaDetail.vue";
import AddDocPersona from "@/components/components-fit/persone/dettaglio-persona/AddDocPersona.vue";
import PreviewDaEstensione from "@/components/components-fit/utility/PreviewDaEstensione.vue";
import Datatable from "../../../../src/components/kt-datatable/KTDatatable.vue";
import { deleteDocumento } from "@/requests/documentiPersona";
import { alertSuccess, alertFailed } from "@/composables/swAlert";
import { downloadElement } from "@/requests/downloadFiles";
import isEnabled from "@/composables/isEnabled.js";

import Swal from "sweetalert2/dist/sweetalert2.min.js";

import { useStore } from "vuex";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import { useRedirectInsegnanti } from "@/composables/redirectInsegnanti";

export default defineComponent({
  name: "documenti-persona",
  props: ["persona"],
  components: {
    DocPersonaDetail,
    AddDocPersona,
    Datatable,
    Loading,
    PreviewDaEstensione,
  },
  setup(props) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const rowsToSkip = computed(() => store.getters.rowsToSkipDocPersona);
    const fetchRows = computed(() => store.getters.fetchRowsDocPersona);
    const sortColumn = computed(() => store.getters.sortColumnDocPersona);
    const sortOrder = computed(() => store.getters.sortOrderDocPersona);

    const getDocumentiPersona = async () => {
      await store.dispatch("setStoreListData", {
        keys: {
          id_persona: props.persona.id,
          rowsToSkip: rowsToSkip.value,
          fetchRows: fetchRows.value,
          sortColumn: sortColumn.value,
          sortOrder: sortOrder.value,
        },
        apiLink: globalApi.LISTA_DOCUMENTI_PERSONA,
        itemName: "documenti_persona_list",
      });
    };

    onBeforeMount(async () => {
      await getDocumentiPersona();
    });

    const setFetchRowsDocPersona = (e) => {
      store.commit("setFetchRowsDocPersona", e);
    };
    const setCurrentPageDocPersona = (page) => {
      store.commit("setCurrentPageDocPersona", page);
    };
    const setSortOrderColumnDocPersona = ({ columnName, order }) => {
      store.commit("setSortColumnDocPersona", columnName);
      store.commit("setSortOrderDocPersona", order);
    };

    const tableHeader = ref([
      {
        key: "file_name",
        sortable: false,
      },
      {
        name: "Tipo",
        key: "tipo",
      },
      {
        name: "Oggetto",
        key: "Oggetto",
      },
      {
        name: "Descrizione",
        key: "Descrizione",
      },
      {
        name: "Data",
        key: "data_add",
      },
      {
        key: "options",
        sortable: false,
      },
    ]);
    const downloadingFile = ref(false);
    const downloadDocument = async (id, estensione) => {
      if (!downloadingFile.value) {
        downloadingFile.value = true;
        await downloadElement(
          id,
          "persone",
          estensione.substring(estensione.length - 3, estensione.length)
        );
        // .then((res) => {
        //   if (res.status != 200) {
        //     alertFailed("Non Riesco a scaricare il documento");
        //   }
        // });
        downloadingFile.value = false;
      }
    };

    const isLoading = ref(false);

    const eliminaFile = () => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Una volta cancellato, non portai piu recuperare il file!",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Elimina",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await deleteDocumento(selectedDocId.value).then((res) => {
            isLoading.value = false;
            if (res.status == 200) {
              alertSuccess("File eliminato");
              getDocumentiPersona();
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
          });
        }
      });
    };
    const selectedDocId = ref(null);
    const disabled = ref(false);
    onMounted(() => {
      setCurrentPageBreadcrumbs("Documenti Persona", [
        "Lista Persone",
        "Dettaglio Persona",
      ]);
    });

    const { pagesLength, redirectInsegnanti, menuLoaded, hasBacheca } =
      useRedirectInsegnanti();

    return {
      isLoading,
      getDocumentiPersona,
      tableHeader,
      eliminaFile,
      setFetchRowsDocPersona,
      setCurrentPageDocPersona,
      setSortOrderColumnDocPersona,
      isEnabled,
      documenti_persona_list: computed(() =>
        store.getters.getStateFromName("resultsdocumenti_persona_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadeddocumenti_persona_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recorddocumenti_persona_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusdocumenti_persona_list")
      ),
      selectedDocId,
      disabled,
      downloadDocument,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),

      pagesLength,
      redirectInsegnanti,
      menuLoaded,
      hasBacheca,
    };
  },
});
</script>

<style scoped>
.form-control {
  height: 28px !important;
}

.form-select {
  height: 28px;
}
</style>

<style>
div.col-2.h-28 > div > div > div > input {
  height: 28px !important;
}

w-5 {
  width: 5%;
}
</style>
