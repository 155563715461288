import services from "@/axios/dbManag";

export const viewDocumento = (item_Id) => {
  return services.dbManag
    .post("/anagrafiche/persone/documenti/view", {
      item_Id,
    })
    .then((res) => {
      return res.data.results[0];
    })
    .catch((e) => e.response);
};

export const deleteDocumento = (item_Id) => {
  return services.dbManag
    .post("/anagrafiche/persone/documenti/del", {
      item_Id,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => e.response);
};
