<template>
  <div
    class="modal fade"
    tabindex="-1"
    id="kt_modal_doc-persona"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="modal-content" v-if="documento.item_id">
          <div class="modal-header">
            <h5 class="modal-title">
              Modifica Documento - {{ persona.nome }} {{ persona.cognome }}
            </h5>

            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body text-start">
            <div class="card-body p-6">
              <div class="ps-5 pe-5">
                <div class="row pb-4">
                  <div class="col-md-12">
                    <label class="fw-bold text-gray-600">Tipologia</label>

                    <SelectInput
                      :options="tipo_docum_persona"
                      :value="documento.id_tipo"
                      name="id_tipo"
                      :disabled="disabled"
                    />
                  </div>
                </div>
                <div class="row pb-4">
                  <div class="col-md-12">
                    <label class="fw-bold text-gray-600">Oggetto</label>

                    <Field
                      name="oggetto"
                      type="text"
                      v-model="documento.Oggetto"
                      class="form-control"
                      :disabled="disabled"
                    />
                    <ErrorMessage
                      class="help-message"
                      style="color: red"
                      name="oggetto"
                    />
                  </div>
                </div>
                <div class="row pb-4">
                  <div class="col-md-12">
                    <label class="fw-bold text-gray-600">Descrizione</label>

                    <Field
                      name="descrizione"
                      as="textarea"
                      type="text"
                      v-model="documento.Descrizione"
                      class="form-control"
                      :disabled="disabled"
                    />
                    <ErrorMessage
                      class="help-message"
                      style="color: red"
                      name="descrizione"
                    />
                  </div>
                </div>
                <div class="row pb-4" v-show="!disabled">
                  <div class="col-md-12">
                    <label class="fw-bold text-gray-600">File</label>
                  </div>

                  <div class="col-lg-11 d-flex ps-0 pe-xl-11">
                    <div
                      class="image-input image-input-outline ms-2"
                      data-kt-image-input="true"
                      style="background-image: url(media/avatars/blank.png)"
                    ></div>
                  </div>
                  <DropZone
                    @files-dropped="addFile($event, supportedFormatPersona)"
                    #default="{ dropZoneActive }"
                  >
                    <label for="file-input-edit-pers" style="cursor: pointer">
                      <span v-if="dropZoneActive">
                        <span>Lascia qui il file </span>
                        <span class="smaller">per aggiungerlo</span>
                      </span>
                      <span v-else>
                        <span>Trascina qui il tuo file</span>
                        <span class="smaller">
                          oppure
                          <strong><em>clicca qui</em></strong>
                          per selezionare il file
                        </span>
                      </span>
                    </label>
                    <input
                      type="file"
                      id="file-input-edit-pers"
                      style="display: none"
                      :accept="supportedFormatPersona"
                      @change="onInputChange"
                    />
                    <ul class="image-list" v-if="file.id">
                      <FilePreview
                        :key="file.id"
                        :file="file"
                        tag="li"
                        @remove="removeFile"
                      />
                    </ul>
                  </DropZone>
                  <div class="form-text">
                    Tipi di file concessi: {{ supportedFormatPersona }}
                  </div>
                </div>
                <ul class="image-list" style="margin-top: 2.5rem">
                  <PreviewUploadedFile
                    :name="documento.file_name"
                    tag="li"
                    @downloadFile="downloadDocumento"
                  />
                </ul>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <span
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="$emit('resetIdDoc')"
            >
              Chiudi
            </span>
            <button
              v-show="!disabled"
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Salva Modifiche
            </button>
            <!--  <button @click="$emit('eliminaDocumento')" class="badge bg-secondary rounded blueFit">
              Elimina File
            </button> -->
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { ref, watch, getCurrentInstance, computed } from "vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import SelectInput from "../../../components-fit/utility/inputs/SelectInput.vue";

//  creare file visualizzazione documento
import { viewDocumento } from "@/requests/documentiPersona";
import { alertFailed, alertSuccess } from "@/composables/swAlert";

import { useStore } from "vuex";
import { sendFiles } from "@/requests/fileSender";
import { downloadElement } from "@/requests/downloadFiles";

//componente documenti
import DropZone from "../../Drag-and-Drop-File/DropZone.vue";
import FilePreview from "../../Drag-and-Drop-File/FilePreview.vue";
import useFileList from "@/composables/file-list";
import PreviewUploadedFile from "../../Drag-and-Drop-File/PreviewUploadedFile.vue";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "documenti-persona-detail",
  emits: ["documentEdited", "resetIdDoc"],
  props: {
    selectedId: {
      type: String,
      required: true,
    },
    persona: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
  components: {
    SelectInput,
    Form,
    Field,
    ErrorMessage,
    DropZone,
    FilePreview,
    PreviewUploadedFile,
    Loading,
  },
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const tipo_docum_persona = computed(() =>
      store.getters.getStateFromName("tipo_docum_persona")
    );
    const keys = ref("tdp");
    if (!tipo_docum_persona.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const documento = ref({});
    const getDocumento = async (value) => {
      documento.value = {};
      await viewDocumento(value).then((res) => {
        if (res.data && res.data.statusCode === 400) {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        } else {
          documento.value = { ...res };
        }
      });
    };

    watch(
      () => props.selectedId,
      async (value) => {
        if (value != null) await getDocumento(value);
        file.value = {};
      }
    );

    const downloadingFile = ref(false);
    const downloadDocumento = async () => {
      if (!downloadingFile.value) {
        downloadingFile.value = true;

        await downloadElement(
          documento.value.item_id,
          "persone",
          documento.value.file_name
        );
        // .then((res) => {
        //
        //   if (res.status != 200) {
        //     alertFailed(
        //       res.data.message
        //         ? res.data.message
        //         : "Attenzione! Si è verificato un errore. Riprovare più tardi"
        //     );
        //     return;
        //   }
        // });
        downloadingFile.value = true;
      }
    };

    const schema = yup.object().shape({
      id_tipo: yup.string().required("Obbligatorio"),
      oggetto: yup.string().required("Obbligatorio"),
      descrizione: yup.string().required("Obbligatorio"),
    });

    // gestione file nuova
    const { file, addFile, removeFile } = useFileList();
    function onInputChange(e) {
      addFile(e.target.files, supportedFormatPersona.value);
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    }
    let bodyFormData = new FormData();

    //form validation
    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = async (values, { resetForm }) => {
      disableButton.value = true;
      isLoading.value = true;

      Object.entries(values).map((item) => {
        bodyFormData.append(item[0], item[1]);
      });
      bodyFormData.append("item_id", documento.value.item_id);
      bodyFormData.append("acl", 0);
      if (file.value.file) {
        bodyFormData.append("file", file.value.file);
        file.value.status = "loading";
      }

      await sendFiles(bodyFormData, globalApi.EDIT_DOCUMENTO_PERSONA).then(
        (res) => {
          const { status, data } = res;
          if (status != 200) {
            file.value.status = false;
            alertFailed(
              data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
          } else {
            file.value.status = true;
            alertSuccess(
              "Documento modificato con successo",
              "kt_modal_doc-persona"
            );
            emit("documentEdited");
            resetForm();
          }
          bodyFormData = new FormData();
          disableButton.value = false;
          isLoading.value = false;
        }
      );
    };

    const onInvalidSubmit = () => {
      alertFailed("Compilare i campi obbligatori");
    };

    const supportedFormatPersona = ref(".doc, .jpg, .pdf, .xls, .ppt, .zip");

    return {
      onSubmit,
      onInvalidSubmit,
      schema,
      downloadDocumento,
      onInputChange,
      removeFile,
      file,
      tipo_docum_persona,
      documento,
      disableButton,
      isLoading,
      supportedFormatPersona,
      addFile,
    };
  },
};
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
